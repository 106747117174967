<script setup lang="ts">
import { useGrowthBook } from '~/composables/useGrowthBook'

import { useInfluencerOnboardingFlowStore } from '~/stores/influencerOnboardingFlow.js'
import { useUserAgencyStore } from '~/stores/userAgency'
import { useUserInfluencerStore } from '~/stores/userInfluencer'

const { id: INFLUENCER_ID } = storeToRefs(useUserInfluencerStore())
const { id: AGENCY_ID } = storeToRefs(useUserAgencyStore())
const { isFeatureOnForGroup } = useGrowthBook()
const { path: curatorV2Path, steps } = storeToRefs(
  useInfluencerOnboardingFlowStore(),
)

const { getMissingStep } = useCuratorOnboardingFlow()

const targetSignup = computed(() => {
  // Start AB test 'curator-onboarding'
  const missingStep = getMissingStep(steps.value)
  const curatorTarget = isFeatureOnForGroup('curator-onboarding', 'new-flow')
    ? `${curatorV2Path.value}/${missingStep}/`
    : '/influencer/signup/'
  // End AB test 'curator-onboarding'
  const artistTarget = '/band/signup/'

  // Most sure path
  if (INFLUENCER_ID.value) return curatorTarget

  if (AGENCY_ID.value) return artistTarget

  // Next best
  const bandTimestamp = evaluateLocalStorageObject('bandSignupTimestamp')
  const influencerTimestamp = evaluateLocalStorageObject(
    'influencerSignupTimestamp',
  )

  if (bandTimestamp && influencerTimestamp)
    return bandTimestamp < influencerTimestamp ? curatorTarget : artistTarget

  if (bandTimestamp) return artistTarget

  if (influencerTimestamp) return curatorTarget

  // Default on band :shrug:
  return artistTarget
})

function evaluateLocalStorageObject(
  key: 'influencerSignupTimestamp' | 'bandSignupTimestamp',
): number | null {
  if (!import.meta.client) return null

  const item = window.localStorage.getItem(key)

  if (!item) return null

  const convertedItem = Number(item)

  if (isNaN(convertedItem)) return null

  return convertedItem
}
</script>

<template>
  <transition name="fade">
    <div
      :key="$route.path"
      class="tw-z-50 tw-w-full tw-bg-orange-500 tw-p-4 tw-text-center tw-text-lg tw-text-white"
      data-test-id="incompleteSignupBanner"
    >
      <i
        class="fas fa-info-circle tw-inline tw-align-middle tw-text-3xl tw-text-white"
      />
      <span>{{ $t('layout.warnings.bandIncomplete00') }}</span>
      <NuxtLinkLocale
        :to="targetSignup"
        class="tw-cursor-pointer tw-font-bold tw-underline"
        data-test-id="incompleteSignupBannerCTA"
      >
        {{ $t('layout.warnings.bandIncomplete01') }}
      </NuxtLinkLocale>
    </div>
  </transition>
</template>
